function setZIndexToNegative() {
  const boxMap = document.querySelector(".contact-section .box-map");
  const boxIcon = document.querySelector(".contact-section .box-icon");

  boxIcon.style.zIndex = "-1";
}

const boxMap = document.querySelector(".contact-section .box-map");
if (boxMap) {
  boxMap.addEventListener("click", setZIndexToNegative);
}