document.addEventListener('DOMContentLoaded', function () {
    var unlinkButton = document.querySelector('.box-newletter .unlink');
    var unsubscribeElement = document.querySelector('.box-newletter .unsubscribe');

    if (unlinkButton && unsubscribeElement) {
        unlinkButton.addEventListener('click', function () {
            unsubscribeElement.classList.toggle('active');
        });
    }
});
