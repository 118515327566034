import Swiper from 'swiper/bundle';

const slide_main = new Swiper(".slides-main .swiper-container", {
    autoplay: {
        delay: 5000,
        disableOnInteraction: true,
    },
    navigation: {
        nextEl: ".slides-main .swiper-button-next",
        prevEl: ".slides-main .swiper-button-prev",
    },
    loop: true,
    direction: 'horizontal',
    autoHeight: true
});

const slide_main_2 = new Swiper(".slides-main-2 .swiper-container", {
    autoplay: {
        delay: 5000,
        disableOnInteraction: true,
    },
    navigation: {
        nextEl: ".slides-main-2 .swiper-button-next",
        prevEl: ".slides-main-2 .swiper-button-prev",
    },
    loop: true,
    direction: 'horizontal',
    autoHeight: true
});


const slide_season = new Swiper(".slides-season .swiper-container", {
    autoplay: {
        delay: 5000,
        disableOnInteraction: true,
    },
    loop: true,
    navigation: {
        nextEl: '.slides-season .swiper-button-next',
        prevEl: '.slides-season .swiper-button-prev',
    },
    direction: 'horizontal',
    autoHeight: true
});


function setButtonTopPosition(selector) {
    const swiperSlide = document.querySelector(selector + ' .swiper-slide img');
    const prevButton = document.querySelector(selector + ' .swiper-button-prev');
    const nextButton = document.querySelector(selector + ' .swiper-button-next');

    if (swiperSlide) {
        const slideHeight = swiperSlide.clientHeight;
        const calculatedTop = slideHeight / 2 + 'px';
        prevButton.style.top = calculatedTop;
        nextButton.style.top = calculatedTop;
    }
}

setButtonTopPosition('.slides-main');
setButtonTopPosition('.slides-main-2');
setButtonTopPosition('.slides-season');

